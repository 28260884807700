<script lang="ts">
  import { siteSettings } from "$lib/stores/siteSettings";

  function toggleSimpleBackground() {
    siteSettings.update((settings) => {
      settings.disableBGBoxes = !settings.disableBGBoxes;
      return settings;
    });
  }
</script>

<div>
  <div class="btn-group dropup w-100">
    <button
      class="btn w-100 appearance-button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      type="button"
      on:click|stopPropagation
    >
      <div class="small">
        Change Appearance<i class="fas fa-wrench ms-2" />
      </div>
    </button>
    <ul class="dropdown-menu">
      <li>
        <button
          class="btn dropdown-item"
          on:click|stopPropagation={toggleSimpleBackground}
        >
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="simplify-background"
              checked={$siteSettings.disableBGBoxes}
            />
            <div class="small">Simplify Background</div>
          </div>
        </button>
      </li>
    </ul>
  </div>

  <div class="policies">
    <a href="/policy/terms-of-service">Terms of Service</a>
    |
    <a href="/policy/privacy-policy">Privacy Policy</a>
  </div>
</div>

<style lang="scss">
  .form-check-input {
    border: 1px solid white;
  }
  .btn {
    border-radius: 0;
  }
  .appearance-button {
    background-color: $bg;
    &:focus {
      outline: 2px solid white;
    }
  }
  .dropdown-item {
    padding: 0.25rem 2rem;
  }
  .form-check {
    margin-bottom: 0;
  }
  .policies {
    margin-top: 0.5rem;
    padding: 0.25rem;
    margin: auto;
    text-align: center;
    a {
      text-decoration: none;
      font-size: 0.75rem;
    }
  }
</style>
